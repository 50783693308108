import React, { ReactElement } from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { Layout } from '../components/common';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import SecureAccessManagementSection from '../components/common/contentSections/SecureAccessManagementSection';
import SecureFeaturesSection from '../components/common/contentSections/SecureFeaturesSection';
import { AccessManagement, Feature } from '../utils/entities';
import { Image } from '../utils/image';
import { StyledButton } from '../components/common/Buttons';

const FeaturesList: Feature[] = [
    {
        title: `Secure`,
        imageUrl: `/images/secure-protocol-secure@3x.png`,
        desc: `A multi-factor authentication solution for employees, partners, and customers accessing apps, systems, and devices`,
    },
    {
        title: `Simple`,
        imageUrl: `/images/secure-protocol-flag-filled@3x.png`,
        desc: `Admins can implement multi-factor authentication easily, without impacting end user productivity`,
    },
    {
        title: `Intelligent`,
        imageUrl: `/images/secure-protocol-artificial-intelligence@3x.png`,
        desc: `Create intelligent policies based on login context that limit identity challenges to risky login attempts`,
    },
];

const AccessManagementList: AccessManagement[] = [
    {
        title: `Location Context`,
        imageUrl: `/images/secure-protocol-secure@3x.png`,
        pointsList: [
            `New city, state, or country`,
            `New geo-location`,
            `Impossible travel`,
        ],
    },
    {
        title: `Device Context`,
        imageUrl: `/images/secure-protocol-flag-filled@3x.png`,
        pointsList: [`New device`, `Managed device`],
    },
    {
        title: `Network Context`,
        imageUrl: `/images/secure-protocol-artificial-intelligence@3x.png`,
        pointsList: [
            `New IP`,
            `Specified IP zones`,
            `Network anonymisers`,
            `Internet service provider ASN`,
        ],
    },
];

const SecureProtocol = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Secured two factor authentication protocol"
                desc="Secure multi-factor authentication solution because 80% of security breaches involve compromised passwords."
                imageUrl="/images/secure-protocol-hero@3x.png"
            />
            <HeadingSection
                heading="Secure"
                subheading="Multifactor authentication"
            />
            <SecureFeaturesSection featureList={FeaturesList} />
            <HeadingSection
                heading="Features"
                subheading="Multifactor authentication"
                desc="Whether you're looking for a two-factor authentication solution to meet compliance targets, or building a full MFA security framework, Okta is the adaptive multi-factor authentication vendor that has you covered.
                Implement a variety of different factors for authentication across usability and assurance levels:"
            />
            <Row noGutters>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `3rem`,
                                    width: `3rem`,
                                    marginTop: `2rem`,
                                    marginBottom: `2rem`,
                                }}
                                src="/images/secure-protocol-protect@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>Security Questioning </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `3rem`,
                                    width: `3rem`,
                                    marginTop: `2rem`,
                                    marginBottom: `2rem`,
                                }}
                                src="/images/secure-protocol-good-pincode@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>Password Verifications </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `3rem`,
                                    width: `3rem`,
                                    marginTop: `2rem`,
                                    marginBottom: `2rem`,
                                }}
                                src="/images/secure-protocol-sms@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>SMS/Software Email OTP</div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `3rem`,
                                    width: `3rem`,
                                    marginTop: `2rem`,
                                    marginBottom: `2rem`,
                                }}
                                src="/images/secure-protocol-fingerprint-scan@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>
                                    Biometrics, Any SAML/OIDC Auth Provider
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row noGutters>
                <Col md="12" lg="4">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body>
                            <div
                                style={{
                                    fontSize: `1.05rem`,

                                    fontWeight: `bold`,
                                }}
                            >
                                <div>Knowledge factors</div>
                            </div>
                            <p>Based on something the user knows</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="4">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body>
                            <div
                                style={{
                                    fontSize: `1.05rem`,

                                    fontWeight: `bold`,
                                }}
                            >
                                <div>Possession factors</div>
                            </div>
                            <p>Based on something the user has</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="4">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body>
                            <div
                                style={{
                                    fontSize: `1.05rem`,

                                    fontWeight: `bold`,
                                }}
                            >
                                <div>Biometric factors</div>
                            </div>
                            <p>Based on something the user is</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <HeadingSection
                heading="Contextual Access Management"
                desc="Implement multi-factor authentication with intelligent access and 
                authentication policies based on login context."
            />
            <SecureAccessManagementSection
                accessManagementList={AccessManagementList}
            />
            <BannerSection />
            <HeadingSection
                heading="Pre-auth sign on policy evaluation"
                desc="Stop brute force attacks in their tracks by evaluating your Okta sign-on policies before credentialevaluation, thereby 
                reducing the likelihood of account lockouts for your workforce."
            />
            <Row noGutters>
                <Col lg="6" md="12">
                    <div
                        style={{
                            fontSize: `1.5rem`,
                            color: `#3c4043`,
                            fontWeight: `bold`,
                            marginTop: `2rem`,
                            marginBottom: `1rem`,
                        }}
                    >
                        Passwordless authentication
                    </div>
                    <p style={{ color: `#535b6c`, fontWeight: `bold` }}>
                        Eliminate the risk of credential attacks and deliver a
                        delightful user experience using passwordless
                        authentication.
                    </p>
                    <p style={{ color: `#535b6c`, fontWeight: `bold` }}>
                        Leverage a range of passwordless authentication options
                        for employees, partners, and contractors using WebAuthn,
                        Factor sequencing, PIV/Smart Cards, Email Magic Links,
                        Device Trust, and Desktop Single Sign-On.{` `}
                    </p>
                    <p style={{ color: `#535b6c`, fontWeight: `bold` }}>
                        Our passwordless authentication technologies help you:
                    </p>
                    <div
                        style={{
                            color: `#535b6c`,
                            paddingRight: `8rem`,
                            paddingLeft: `1rem`,
                        }}
                    >
                        <div style={{display: 'flex', }}>
                        <Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`,
                                    marginRight: `1rem`,
                                    marginTop: `0.3rem`,
                                    alignSelf: `flex-start`,
                                }}
                                src="/images/check.svg"
                            />
                        <p>
                            Leverage session risk to dynamically alter the
                            authentication experience
                        </p>
                        </div>
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        <Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`,
                                    marginRight: `1rem`,
                                    marginTop: `0.3rem`,
                                    alignSelf: `flex-start`,
                                }}
                                src="/images/check.svg"
                            />
                        <p>
                            Delight users with one-click or one-touch
                            authentication across desktop and mobile
                        </p>
                        </div>
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        <Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`,
                                    marginRight: `1rem`,
                                    marginTop: `0.3rem`,
                                    alignSelf: `flex-start`,
                                }}
                                src="/images/check.svg"
                            />
                        <p>
                            Reduce IT help-desk/support costs associated with
                            password management
                        </p>
                        </div>
                    </div>
                </Col>
                <Col lg="6" md="12">
                    <Image
                        style={{
                            marginTop: `2rem`,
                            marginBottom: `2rem`,
                        }}
                        src="/images/secure-protocol-passwordless@3x.png"
                    />
                </Col>
            </Row>
            <Row noGutters>
                <Col lg="5" md="12">
                    <Image
                        style={{
                            marginTop: `2rem`,
                            marginBottom: `2rem`,
                            height: `40rem`,
                            width: `25rem`,
                        }}
                        src="/images/secure-protocol-enhanced@3x.png"
                    />
                </Col>
                <Col lg="7" md="12">
                    <div
                        style={{
                            fontSize: `1.5rem`,
                            color: `#3c4043`,
                            fontWeight: `bold`,
                            marginTop: `2rem`,
                            marginBottom: `1rem`,
                        }}
                    >
                        Enhanced user & admin experience
                    </div>

                    <p style={{ color: `#535b6c`, fontWeight: `bold` }}>
                        Adaptive multi-factor authentication software includes
                        features that improve user experience while enhancing
                        the security posture
                    </p>
                    <div
                        style={{
                            color: `#535b6c`,
                            paddingRight: `8rem`,
                            paddingLeft: `1rem`,
                        }}
                    >
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        <Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`,
                                    marginRight: `1rem`,
                                    marginTop: `0.3rem`,
                                    alignSelf: `flex-start`,
                                }}
                                src="/images/check.svg"
                            />
                        <p>
                            Authenticate without a password: Enable user
                            authentication by using other factors in lieu of a
                            password
                        </p>
                        </div>
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        <Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`,
                                    marginRight: `1rem`,
                                    marginTop: `0.3rem`,
                                    alignSelf: `flex-start`,
                                }}
                                src="/images/check.svg"
                            />
                        <p>
                            Delight users with one-click or one-touch
                            authentication across desktop and mobile
                        </p>
                        </div>
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        <Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`,
                                    marginRight: `1rem`,
                                    marginTop: `0.3rem`,
                                    alignSelf: `flex-start`,
                                }}
                                src="/images/check.svg"
                            />
                        <p>
                            Seamless enrollment: Self-service multi-factor
                            authentication enrollment during initial login
                        </p>
                        </div>
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        <Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`,
                                    marginRight: `1rem`,
                                    marginTop: `0.3rem`,
                                    alignSelf: `flex-start`,
                                }}
                                src="/images/check.svg"
                            />
                        <p>
                            Flexible authentication: Select from a variety of
                            end user experiences, including 1-click
                            authentication
                        </p>
                        </div>
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        <Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`,
                                    marginRight: `1rem`,
                                    marginTop: `0.3rem`,
                                    alignSelf: `flex-start`,
                                }}
                                src="/images/check.svg"
                            />
                        <p>
                            Simple reporting and auditing: Detailed
                            authentication logs, such as login attempts, with
                            preset reports for audits and easy integration with
                            security tools
                        </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <HeadingSection
                heading="Integrations"
                desc="Integrate multi-factor authentication across your entire organization."
            />
            <Row noGutters>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `3rem`,
                                    width: `3rem`,
                                    marginTop: `2rem`,
                                    marginBottom: `2rem`,
                                }}
                                src="/images/secure-protocol-office365@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>Office 365 </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `3rem`,
                                    width: `3rem`,
                                    marginTop: `2rem`,
                                    marginBottom: `2rem`,
                                }}
                                src="/images/secure-protocol-zoom@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>Zoom </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `3rem`,
                                    width: `3rem`,
                                    marginTop: `2rem`,
                                    marginBottom: `2rem`,
                                }}
                                src="/images/secure-protocol-slack@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>Slack Channel </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `5rem`,
                                    width: `5rem`,
                                    marginTop: `1rem`,
                                    marginBottom: `1rem`,
                                }}
                                src="/images/secure-protocol-atlassian@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>Provider </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row noGutters>
                <Col md="12" lg="3"></Col>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `5rem`,
                                    width: `5rem`,
                                    marginTop: `1rem`,
                                    marginBottom: `1rem`,
                                }}
                                src="/images/secure-protocol-dropbox@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>Drop Box </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="3">
                    <Card
                        style={{
                            color: `#535b6c`,
                            margin: `1rem`,
                            backgroundColor: `#f7f7f7`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                            }}
                        >
                            <Image
                                style={{
                                    height: `3rem`,
                                    width: `3rem`,
                                    marginTop: `2rem`,
                                    marginBottom: `2rem`,
                                }}
                                src="/images/secure-protocol-email@2x.png"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                    height: `3.5rem`,
                                }}
                            >
                                <div>Email OTP </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12" lg="3"></Col>
            </Row>
            <HeadingSection
                heading="Multi-Factor Authentication Pricing"
                desc="Integrate multi-factor authentication across your entire organization."
            />
            <Table responsive="lg" style={{ color: `#171717` }}>
                <thead>
                    <tr style={{ fontSize: `1.5rem` }}>
                        <th>Topics</th>
                        <th>Pricing 1</th>
                        <th>Pricing 2</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ fontSize: `1.5rem` }}>
                            <b>Feature</b>
                        </td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                    </tr>
                    <tr>
                        <td>Knowledge factors</td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                    </tr>
                    <tr>
                        <td>Possession factors</td>
                        <td></td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                    </tr>
                    <tr>
                        <td>Biometric factors</td>
                        <td></td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                    </tr>
                    <tr>
                        <td style={{ fontSize: `1.5rem` }}>
                            <b> Contextual Access Management</b>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Location context</td>
                        <td></td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                    </tr>
                    <tr>
                        <td>Device context</td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                    </tr>
                    <tr>
                        <td>Integrations</td>
                        <td></td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                    </tr>
                    <tr>
                        <td>Enhanced admin & end user experience</td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                        <td><Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`
                                }}
                                src="/images/check.svg"
                            /></td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                        <StyledButton>
                        <span style={{ color: `#2382b6` }}>
                        See pricing for all MFA features
                        </span>
                    </StyledButton>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    </Layout>
);

export default SecureProtocol;
