import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AccessManagement } from '../../../utils/entities';
import { Image } from '../../../utils/image';

interface FeaturesSectionProps {
    accessManagementList: AccessManagement[];
}

const SecureAccessManagementSection = ({
    accessManagementList,
}: FeaturesSectionProps): ReactElement => (
    <Row noGutters>
        {accessManagementList.map(({ title, pointsList, imageUrl }) => (
            <Col
                key={title}
                md="12"
                lg="4"
                style={{ textAlign: `center`, marginBottom: `1rem` }}
            >
                <div style={{ textAlign: `center` }}>
                    <Image
                        style={{ height: `4rem`, width: `4rem` }}
                        src={imageUrl}
                    />
                </div>
                <div
                    style={{
                        fontSize: `1.2rem`,
                        fontWeight: `bold`,
                        paddingTop: `1rem`,
                    }}
                >
                    {title}
                </div>
                <div
                    style={{
                        padding: `1rem`,
                        paddingLeft: `5rem`,
                        paddingRight: `1rem`,
                        color: `#535b6c`,
                        textAlign: `left`,
                    }}
                >
                    {pointsList.map((point, i) => (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <Image
                                style={{
                                    height: `1rem`,
                                    width: `1rem`,
                                    border: `0.5px solid #4589ff`,
                                    marginRight: `1rem`,
                                }}
                                src="/images/check.svg"
                            />
                        <p style={{
                                    height: `1rem`,
                                    paddingTop: '0.3rem'
                                    }} key={i}>{point}</p>
                        </div>
                    ))}
                </div>
            </Col>
        ))}
    </Row>
);

export default SecureAccessManagementSection;
